:root {
  --Gris-clair: #f8f8f8;
  --Gris-moyen-clair: #f1f2f2;
  --Gris-fonc: #53585f;
  --Gris-fonc-moyen:#A5A9A8;
  --Rouge: #C72506;
  --Rouge-bordeau: #851001;
  --Rouge-vif-medium: #B8413C;
  --Rouge-vif: #eb5d57;
  --Rouge-dark: #6D1206;
  --Rouge-vif-60:#F39E9A;
  --Noir: #1A171B;
  --Blanc: #FFF;
}

.App {
  text-align: center;
}

table {
  --bs-table-striped-bg: hsl(0, 0%, 99%) !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: Calibri;
  src: url("./assets/fonts/calibri-regular.ttf");
}

@font-face {
  font-family: Calibri;
  src: url("./assets/fonts/calibri-bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Calibri;
  src: url("./assets/fonts/calibri-bold-italic.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Calibri;
  src: url("./assets/fonts/calibri-italic.ttf");
  font-style: italic;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

#root {
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  //background: var(--Rouge-bordeau);
  width: 145.02px;
  height: 4px;
  /* Gris foncé moyen */
  background: #a5a9a8;
  border-radius: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--Rouge-bordeau);
}

.font-bold {
  font-weight: bold !important;
  color: #1a171b !important;
}

button:active,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control:active,
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--Rouge-bordeau) !important;
}

.btn-nav {
  text-decoration: none !important;

  &:active {
    text-decoration: none !important;
  }
}

.no-background .ant-table {
  background-color: transparent !important;
}

.no-background .ant-table tbody tr:hover td {
  background-color: transparent !important;
}

.rbt-aux {
  display: none;
}

.rbt-menu.dropdown-menu.show {
  max-height: 190px !important;
  width: 100% !important;
  margin-top: 4px !important;
}

.rbt-input-multi.focus {
  border: 1px solid #ced4da;
  box-shadow: none;
  color: unset;
}

.ant-table-wrapper .ant-table-filter-column {
  place-items: center !important;
}

.span-required {
  color: #e9432d !important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

input[type="text"],
input[type="password"] {
  &.invalid {
    border: 1px solid red !important;
  }
}

.error-msg {
  color: red;
}

.border-base {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: max-content;
  height: 25px;
  border: 1px solid #f39e9a !important;
  padding: 0px 10px;
  /* Blanc */
  background: #ffffff;
  border-radius: 20px;
}

.border-staus {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: max-content;
  height: 25px;
  border: 1px solid var(Rouge-vif);
  padding: 15px 22px;
  /* Blanc */
  background: #ffffff;
  border-radius: 20px;
  color: var(Rouge-vif);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.border-gray {
  border: 1px solid #dbdddf !important;
  padding: 2px 10px;
  /* Blanc */
  background: #ffffff;
  height: 25px;
  gap: 10px;
  border-radius: 20px;
}

.border-green {
  border: 1px solid #01d16d !important;
}

.border-red {
  border: 1px solid #ff0505 !important;
}

i.rounded-icon {
  overflow: hidden;
  margin-top: 11px;
  margin-right: 10px;
  cursor: pointer;
}

.boldered-text {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #1a171b;
}

.arrow-close {
  svg {
    fill: var(--Rouge-bordeau) !important;
  }
}

.form-check-input {
  border-color: var(--Rouge-bordeau) !important;
  padding: 2px !important;
  margin-left: -17px !important;
  margin-top: 5px !important;
}

.form-check-input:checked {
  background-color: var(--Rouge-bordeau) !important;
  border-color: var(--Rouge-bordeau) !important;
}

i {
  margin: 0px 2px;
}

.span-icon {
  display: block;
  padding: 4px;
  font-weight: 400;
  cursor: pointer;

  label {
    font-weight: 400;
  }
}

.span-icon-disabled {
  display: block;
  padding: 4px;
  font-weight: 400;
  opacity: 0.5 !important;
  cursor: no-drop;

  label {
    font-weight: 400;
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

body,
html {
  height: 100%;
  background-color: #ffffff;
}

body * {
  font-family: "Calibri";
}

ul,
li {
  list-style-type: none;
}

.roundedBtn {
  border: none;
  border-radius: 8px !important;
  padding: 2px 2px !important;
  font-weight: 600 !important;
  background-color: transparent !important;
}

/*Button*/
.btn {
  border-radius: 8px !important;
  padding: 5px 15px !important;
  font-weight: 600 !important;
  border-color: transparent !important;

  &.primary {
    background: #dbdddf;
    color: var(--Gris-fonc);
  }

  &.submit {
    background: vqr(--Rouge-vif) !important;
    color: var(--Gris-fonc);
    color: white;
  }

  &:hover {
    background-color: #b8413c !important;
    color: #fff !important;
  }

  &.red:hover {
    background-color: #b8413c !important;
    color: #fff !important;
  }

  &:focus,
  &.active-link {
    background-color: var(--Rouge-vif) !important;
    border-color: var(--Rouge-vif) !important;
    color: #fff !important;

    span {
      color: #fff;
    }
  }

  &.red {
    background: #6d1206 !important;
    color: #fff !important;
  }

  &.disabled {
    background: #fce7e6 !important;
    color: #f39e9a !important;
  }
}

.leftSide {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 0.8s ease-in-out;
  float: left;
  // max-width: 265px;
  width: 12vw;//13.8vw;
  height: 100vh;
  background-color: var(--Gris-moyen-clair);
  overflow-y: auto;
  padding: 35px 20px 35px 20px;
  padding-right: 0;
  resize: horizontal;
  position: fixed;
  z-index: 9999;
  overflow-x: hidden;

  ul,
  li {
    list-style-type: none;
    padding: 0;
  }

  .logo {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 40px;
    position: relative;

    .logoHeader {
      position: absolute;
      opacity: 1;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;
    }

    .smallLogoHeader {
      position: absolute;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;
      opacity: 0;
    }
  }

  .menu {
    position: relative;

    ul {
      li {
        margin: 8px 0;

        a {
          padding-left: 8px;
          display: flex;
          align-items: center;
          text-decoration: none;
          color: var(--Gris-fonc);

          &:active {
            background: var(--Rouge-vif);
            border-radius: 4px 0px 0px 4px;
          }

          &:hover {
            background-color: #dbdddf;
            border-radius: 8px 0px 0px 8px;
            border-right: solid 5px var(--Rouge-vif);
          }

          span,
          img {
            font-size: 22px;
            margin-right: 10px;
          }

          .txt-menu {
            font-size: inherit;
            transition: opacity 1s ease-in-out;
            opacity: 1;
            padding: 0.5rem 0;
            white-space: nowrap !important;
          }
        }

        &:first-child {
          margin-top: 30px;
        }
      }

      .logOut {
        border-top: 1px solid #d9d9d9;
        padding-top: 40px;
      }
    }

    .lastItem {
      margin-bottom: 45px;
    }

    .arrow-menu {
      text-decoration: none;
      color: #fff;
      background: var(--Rouge-vif);
      display: flex;
      width: 25px;
      height: 25px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      right: 0;
      top: -43px;

      &:before {
        content: "\276E";
      }
    }
  }

  &.no-txt-menu {
    width: 80px;
    overflow-x: hidden;

    .txt-menu {
      opacity: 0 !important;
    }

    .logo {
      padding-right: 25px;

      .logoHeader {
        opacity: 0;
      }

      .smallLogoHeader {
        opacity: 1;
      }
    }

    .arrow-menu {
      transform: rotate(180deg);
    }
  }
}

.rightSide {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: padding 0.8s ease-in-out;
  height: 100% !important;

  .headRightSide {
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    margin: 0 20px 0 0;
  }

  &.container {
    padding: 0 20px 0 12.6vw;//14.8vw;
    max-width: 100%;
    width: 100%;
    background-color: #ffffff !important;
  }
  .quick-filter {
    width: 320px;
    max-width: 60%;
  }
  .search {
    display: flex;
    align-items: center;
    margin-left: 10px;
    height: 40px;
    background: var(--Gris-moyen-clair);
    border: 1px solid var(--Gris-moyen-clair);
    border-radius: 8px;
    font-size: 14px;

    span {
      font-size: 20px;
      margin-left: 15px;
      margin-right: 5px;
    }

    input {
      width: 100%;
      border: none;
      background: var(--Gris-moyen-clair);
      height: 36px;

      &:focus-within {
        outline: 0;
      }
    }

    &.red {
      border: 1px solid #6d1206;
      background: #6d1206;

      input {
        background: #6d1206;
        color: #fff;
      }

      span {
        color: #fff;
      }

      ::placeholder {
        color: #fff;
      }
    }
  }

  .myAccount {
    display: flex;
    align-items: center;
    padding: 24px 15px;

    .p-profil {
      margin-right: 10px;
    }

    .name {
      .react-select__control,
      .react-select__option {
        border: none;
        width: inherit;
        margin: 0;
        border-radius: 0;
      }

      .react-select__indicator {
        svg {
          fill: inherit !important;
        }
      }

      input,
      ul.submenu {
        display: none;
      }

      label {
        position: relative;
        display: block;
        cursor: pointer;
        font-size: 16px;
      }

      input:checked ~ ul.submenu {
        display: block;
      }

      .check01 {
        img {
          margin-left: 20px;
          width: 12px;
        }
      }

      .submenu {
        transition: all 500ms;
        position: absolute;
        background: var(--Rouge-vif);
        border-radius: 5px;
        padding: 15px 20px 10px 10px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
        min-width: 140px;
        margin-top: 5px;

        a {
          color: #ffffff;
          text-decoration: none;

          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;

          img {
            width: 21px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .data-base {
    background: var(--Gris-moyen-clair);
    padding-bottom: 15px;

    .database-search {
      display: flex;
      align-items: center;
      background: var(--Rouge-bordeau);
      border-radius: 0px 0px 16px 16px;
      height: 60px;
    }

    .database-team {
      margin-left: 10px;

      ul,
      li {
        list-style-type: none;
        padding: 0;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0;

        li {
          margin: 10px 10px 0 0;

          a {
            display: flex;
            align-items: center;
            color: var(--Gris-fonc);
            background-color: #dbdddf;

            span {
              margin-right: 5px;
              font-size: 22px;
            }
          }
        }
      }
    }

    .database-logo {
      display: flex;
      align-items: center;
      margin: 0 15px;

      span {
        font-size: 24px;
        color: #fff;
        margin-left: 10px;
      }
    }
  }

  .add-group {
    margin-top: 20px;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;

      li {
        a {
          img {
            width: 25px;
            height: 25px;
            object-fit: cover;
          }
        }
      }
    }
  }

  .select {
    width: 100%;

    .icon-search {
      background: #6d1206;
      border: 1px solid var(--Rouge-bordeau);
      border-radius: 28px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 195px;
      font-size: 23px;
      margin: 0 0 0 15px;
      cursor: pointer;
    }
  }

  &.no-txt-menu {
    padding: 0 20px 0 9rem;
  }
}

/*Select*/
.react-select__indicator {
  svg {
    fill: var(--Rouge-bordeau) !important;
  }
}

.react-select__indicator-separator {
  display: none;
}

.react-select__control {
  border: 1px solid #dbdddf;
  border-radius: 8px;
  //width: 180px;
  margin: 0 0 0 15px;

  &:focus {
    border: none;
  }
}

.react-select__menu {
  background: #ffffff;
  border: 1px solid #dbdddf;
  border-radius: 8px;
  left: 15px;
  padding: 10px;
  z-index: 4 !important;
}

.react-select__option {
  background-color: #fff;
  border: 1px solid #f39e9a;
  border-radius: 20px;
  margin: 10px 0 0px 0;

  &.react-select__option--is-selected {
    background-color: #fff;
    color: initial;
  }
}

.react-select__control--menu-is-open {
  .react-select__indicator {
    svg {
      transform: rotate(180deg);
    }
  }
}

.react-select__option--is-focused {
  background-color: #f39e9a !important;
  color: #fff !important;
}

.css-b62m3t-container {
  width: 100%;
  height: max-content;
}

.modalAddGroup {
  //background-color: #000;

  .modal-dialog {
    max-width: 80%;
    margin-right: 25px;
  }

  .modal-body {
    background: var(--Gris-moyen-clair);
  }

  .modal-header {
    background: var(--Rouge-bordeau);
    color: #fff;
    font-size: 18px !important;

    .btn-close {
      background: url(././assets/img/cancel.svg);
      background-repeat: no-repeat;
      opacity: 1;
    }
  }

  .modal-footer {
    justify-content: center;
  }

  .react-select__control {
    width: inherit;
    margin: 0;
  }

  .ultimateBen,
  .kyc,
  .ultimateBen > div {
    background: #ffffff;
    box-shadow: 2px 2px 9px rgba(165, 169, 168, 0.15);
    border-radius: 8px;
    padding: 10px;
  }

  .ultimateBen {
    .ico {
      background: var(--Rouge-vif);
      border-radius: 2px;
      color: #fff;
      padding: 6px;
      margin: 8px;
      cursor: pointer;

      &.disabled {
        background: #e7e7e7;
      }
    }

    label {
      width: 360px;
    }
  }

  /*.form-label {
    margin-bottom: 0;
  }*/

  label {
    color: #1a171b;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    &.txt {
      width: 160px;
      max-width: 135px;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      padding-right: 10px;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    &.txt_comments {
      width: 160px;
      max-width: 135px;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      padding-right: 10px;
      padding-top: 10px;
    }

    &.txt-currency {
      width: 100px;
      max-width: 105px;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }

    &.txt-with-dropdown {
      width: 182px !important;
      text-overflow: ellipsis;
      white-space: break-spaces;
      overflow: hidden;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    &.txt-with-dropdown2 {
      width: 260px !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    &.radio {
      width: 165px;
    }

    &.radio-echange {
      width: 130px;
    }
  }

  .form-check {
    margin-right: 10px;
  }

  .form-check-input[type="checkbox"] {
    border-radius: 50%;
  }

  input[type="text"] {
    &.invalid {
      border: 1px solid red;
    }
  }

  .allOneOff {
    a,
    span {
      font-weight: 600;
      padding: 2px 10px;
      font-size: 14px;
      border-radius: 20px;
      margin: 0 5px 0 0;
      cursor: pointer;
      text-decoration: none;
      color: #1a171b;
    }

    .active-on {
      background-color: #01d16d;
      color: white;
    }

    .active-off {
      background-color: #a5a9a8;
      color: white;
    }

    .all {
      border: 1px solid #f39e9a;
    }

    .one {
      border: 1px solid #01d16d;

      // &:hover.one {
      //   background-color: #01d16d;
      //   color: white;
      // }
    }

    .off {
      border: 1px solid #a5a9a8;

      // &:hover.off {
      //   background-color: #A5A9A8;
      //   color: white;
      // }
    }
  }

  .required {
    label {
      position: relative;

      &:after {
        position: absolute;
        top: -1px;
        right: 90px;
        content: "*";
        color: #e9432d;
        font-weight: bold;
      }
    }
  }
}

.inputPerso {
  // background: url(././assets/img/arrow.svg);
  background-repeat: no-repeat;
  background-position: right center;
  width: 100%;
  cursor: context-menu;

  &:focus {
    //background: url(././assets/img/arrow-up.svg);
    background-repeat: no-repeat;
    background-position: right center;
    cursor: context-menu;
  }
}

.contour {
  position: relative;
  padding: 2.16rem 0.75rem 0.375rem 0.75rem !important;
  cursor: pointer;
}

.react-datepicker__day {
  border: solid 1px #f39e9a;
  font-weight: bolder;
  color: black;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: 1px solid #f39e9a !important;
}

.react-datepicker__day-name {
  color: #f39e9a !important;
  font-weight: 800;
}

.react-datepicker__current-month {
  font-weight: bolder;
}

.react-datepicker__day--selected:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #f39e9a !important;
}

.react-datepicker__day--selected {
  background-color: #f39e9a !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--Rouge-bordeau);
  border-color: var(--Rouge-bordeau);
}

.ml-spacer {
  margin-left: 0.5rem;
}

.btn-list-entity {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 22px;
  border: 1px solid white;
  border-radius: 8px !important;
  text-decoration: none;

  &:hover :active {
    text-decoration: none !important;
  }
}

.form-group-title {
  background: #ffffff !important;
  color: var(--Rouge-bordeau) !important;
  box-shadow: 1px 1px 6px rgba(165, 169, 168, 0.25);
  border-radius: 8px;
  border: 1px;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 22px;
  text-decoration: none;

  &:hover :active {
    text-decoration: none !important;
  }
}

.picker-select {
  z-index: 5 !important;
}

.react-datepicker-popper {
  z-index: 5 !important;
}

.divider {
  width: auto;
  height: 1px;

  /* Gris moyen clair */

  background: #dbdddf;
}

.boldered-header {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  padding-top: 24px;
  display: flex;
  align-items: center;

  /* Noir */

  color: #1a171b;
}

.ant-empty-normal {
  margin-block: 0px;
}

.ant-select-dropdown {
  z-index: 1900 !important;
}

.ant-picker-dropdown {
  z-index: 1900 !important;
}

.react-datepicker__day--outside-month {
  color: #f39e9a !important;
}

.email {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.88);

  &:hover {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.88);
  }
}

.list-label-btn {
  width: 150px;
}

.list-btn {
  cursor: pointer;

  span {
    font-weight: 600;
    padding: 5px 23px;
    font-size: 14px;
    border-radius: 20px;
    margin: 0 15px 0 0;
  }

  .all {
    border: 1px solid var(--Rouge-vif);
  }

  .selected {
    border: 1px solid var(--Rouge-vif);
    background: var(--Rouge-vif);
    color: #ffffff;
  }

  .off {
    border: 1px solid #ff0505;
  }
}

.boldered-text-title {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px;
  /* identical to box height */
  display: flex;
  align-items: center;
}

.ant-popover {
  z-index: 3000;
}

.cutom-btn {
  background-color: #6d1206 !important;
  color: white !important;
  width: 180px;
  height: 40px;
}

.ant-input-number-handler-wrap {
  &:hover {
    display: none !important;
  }

  display: none !important;
}

.ant-dropdown-placement-bottomRight {
  z-index: 10000 !important;
}

.calendar-icon {
  background: url("./assets/img/Calendar.svg") no-repeat right;
  background-size: 20px;
  width: 240px;
  height: 24px;
}

.btnSearch {
  background-color: #830c0c !important;
}

.gray-rounded {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 25px;
  border: 1px solid var(--Gris-fonc);
  border-radius: 20px;
  color: var(--Gris-fonc);
  padding: 0px 12px 0px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  max-width: max-content;
  min-width: max-content;
}

.gray-rounded-flou {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 25px;
  border: 1px solid #a5a9a8;
  border-radius: 20px;
  color: #a5a9a8;
  padding: 0px 5px 0px 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  max-width: max-content;
  min-width: max-content;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;
  gap: 10px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid var(--Rouge-vif);
  color: var(--Rouge-vif);
  min-width: min-content;
  max-width: max-content;
}

.disabled-content {
  pointer-events: none;
  opacity: 1;
  background-color: transparent !important;
  cursor: not-allowed;
}

.disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.bg-gray {
  background-color: rgb(172, 172, 172);
}

.mt-n1 {
  margin-top: -0.25em !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  cursor: pointer;
  max-height: 40px;
  overflow-y: hidden;
}

.editableRow:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #830c0c;
}

tr[rowclassname="selectRowClassName"]:hover {
  cursor: pointer;
  cursor: hand;
}

.formNumber {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.elementFormNumber {
  border: 1px solid #dbdddf !important;
  border-radius: 4px !important;
  padding-left: 0px !important;
  padding-right: 8px !important;
  height: 38px;
  vertical-align: middle !important;
  place-items: center !important;
  background-color: #ffffff;
}

.headerCustom {
  max-width: 150px !important;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;

  span {
    overflow-x: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
}

.contentHeaderCustom {
  max-width: 150px !important;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;

  span {
    overflow-x: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
}

.btnCancel {
  background-color: #dbdddf !important;

  &:hover {
    background-color: #dbdddf !important;
    opacity: 0.9;
    color: #1d1d1d !important;
  }
}

.btnSave {
  background-color: var(--Rouge-bordeau) !important;
  color: #fce7e6 !important;

  &:disabled {
    background-color: #fce7e6 !important;
    color: #f39e9a !important;
    opacity: 0.9 !important;
  }
}

.ant-select-multiple {
  &:hover {
    border-color: var(--Rouge-bordeau) !important;
  }
}

.ant-select-selctor {
  &:hover {
    border-color: var(--Rouge-bordeau) !important;
  }
}

.txt-started {
  align-items: baseline !important;

  @media (max-width: 1518px) {
    align-items: start !important;
  }
}

.fitSize {
  width: 100px !important;
}

.custom-datepicker-fixed {
  position: fixed !important;
}

.custom-datepicker-day-disabled {
  background-color: #fce7e6;
  cursor: no-drop;
  color: #f39e9a;

  &:hover {
    background-color: #fce7e6;
  }
}

.custom-datepicker-day-today {
  background-color: #fce7e6;

  &:hover {
    background-color: #f39e9a;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: #fce7e6;
}

.isEmptyRequired {
  padding: 5px;
  background-color: #f39e9a;
  color: red;
  border: red 1px solid;
}

.ant-table-cell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.ant-table-placeholder {
  background-color: #ffffff;
}

.content-table {
  overflow: auto;
}
.content-table-modal {
  overflow-y: visible;
  overflow-x: auto;
}
.content-table-modal-content {
  overflow-y: visible;
  overflow-x: auto;
}

.c_pointer {
  cursor: pointer;
}

.hover_color_primary {
  &:hover {
    color: var(--Rouge-bordeau) !important;
  }
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.my-auto-text-area {
  margin-top: 0px !important;
}

.txt-two-line {
  margin-right: 15px;
}

.txt-two-line-textarea {
  margin-right: 20px;
}

.txt-label-area {
  margin-right: 60px !important;
}

.txt-label-area-comments {
  margin-right: 72px !important;
}

.txt-center-fix {
  height: 26px;
}

.txt-counter {
  margin-right: 50px;
  margin-top: 0px;
  margin-bottom: 0px !important;
}

.label-text-area {
  width: 164px;
  margin-right: 0px !important;
}

.label-center-fixed {
  padding-top: 12px !important;
}

#modalUnsaved > div,
#modalAddIban > div {
  box-shadow: 0px 0px 255px 255px rgba(178, 178, 178, 0.45),
    25px 0px 20px -20px rgba(0, 0, 0, 0.45),
    0px 25px 20px -20px rgba(0, 0, 0, 0.45),
    -25px 0px 20px -20px rgba(0, 0, 0, 0.45);
}

#modalAddIban > div {
  max-width: 500px;
  padding: 10px;
  border-radius: 5px;
}

input::-webkit-contacts-auto-fill-button,
div::-webkit-contacts-auto-fill-button,
div::-webkit-credentials-auto-fill-button,
input::-webkit-credentials-auto-fill-button,
*::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

// for react-datepicker
.react-datepicker__input-container > .form-control {
  padding-inline-end: 0px !important;
}

.react-datepicker__tab-loop {
  position: fixed;
  margin-top: -48px;
  z-index: 1111;
}

.fix-datepicker-show {
  width: 256px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #830c0c !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* styles/index.css for bd padf viewer */
.rpv-core__inner-pages,
.rpv-core__inner-page {
  background-color: rgb(102, 102, 102) !important;
}

// .MuiAvatar-img {
//   width: auto!important;
//   object-fit: unset!important;
// }

 .ant-avatar-image {
    border: none;
   img {
     width: auto !important;
     object-fit: unset !important;
   }
 }

.selectRowClassName {
  cursor: pointer;
}

.avatar-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: none;
}

.position-relative:hover .camera-icon {
  display: block;
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #000;
}